import React from 'react'
import Layout from '../components/layout'
import Banner from '../components/partials/banner'
import { css } from '@emotion/core'
import useScript from '../hooks/useScript';

  
const NewsletterPage = () => {
  
  useScript(
    'https://lucky-pinecone.ck.page/d050164a38/index.js'
  );

  return <Layout title="Mastery Games Newsletter">
    <Banner />

    <div css={css`
      padding: 5rem 3rem;
      display: grid;
      grid-auto-columns: 1fr;
      grid-gap: 2rem;
      justify-content: center;
      grid-template-areas: "image"
                           "heading"
                           "form";

      @media (min-width: 800px) {
        grid-template-areas: "image heading"
                             "image form";
      }
    `}>
      <div css={css`
        background-size: contain;
        background-position: center right;
        min-height: 300px;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-image: url(/img/racoon.jpg);
        grid-area: image;
      `}></div>
      <div css={css``}>
        <h1>Mastery Games Newsletter</h1>
        <p>Get the best web dev tips, blog posts & cool finds — from me to you!</p>
      </div>
      
      <div css={css`grid-area: form;`}>
        <script data-uid="d050164a38"></script>
      </div>
    </div>

  </Layout>
}

export default NewsletterPage
